<template>
  <div>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!--begin::Card-->
            <div class="card card-custom gutter-b example example-compact">
              <div class="card-header">
                <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                  Ajouter un article
                </h3>
                <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                  Modifier un article
                </h3>
              </div>
              <!--begin::Form-->
              <form class="form" @submit.prevent="save">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-lg-5">
                      <label>Titre:</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.title"
                        :class="{ 'is-invalid': errors.subtitle }"
                      />
                      <span class="form-text text-danger" v-if="errors.title">{{
                        errors.title[0]
                      }}</span>
                    </div>
                    <div class="col-lg-5">
                      <label>Catégorie:</label>
                      <multiselect
                        v-model="form.category"
                        :options="option.categories"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :hide-selected="true"
                        placeholder="Choisir une catégorie"
                        label="nom"
                        track-by="id"
                        select-label=""
                        deselect-label=""
                        @input="setCatPostId()"
                        :class="{ 'is-invalid': errors.category }"
                      ></multiselect>
                      <span
                        class="form-text text-danger"
                        v-if="errors.cat_post_id"
                        >{{ errors.cat_post_id[0] }}</span
                      >
                    </div>
                    <div class="col-lg-2">
                      <label>Mettre en ligne:</label>
                      <div class="radio-inline">
                        <label class="radio radio-solid">
                          <input
                            type="radio"
                            v-model="form.publish"
                            value="1"
                          />
                          <span></span>OUI</label
                        >
                        <label class="radio radio-solid">
                          <input
                            type="radio"
                            v-model="form.publish"
                            value="0"
                          />
                          <span></span>NON</label
                        >
                        <span
                          class="form-text text-danger"
                          v-if="errors.publish"
                          >{{ errors.publish[0] }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-left:-10px;">
                    <div class="col-md-5">
                      <div class="col-md-12">
                        <label style="margin-left:-20px;"
                          >Photo de couverture
                          <input
                            type="file"
                            accept="image/*"
                            class="form-control"
                            ref="fileInput"
                            @input="pickFile"
                          />
                        </label>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group" style="margin-left:-20px;">
                          <label for="">Sous titre</label>
                          <textarea
                            v-model="form.subtitle"
                            class="form-control"
                            cols="28"
                            rows="2"
                            :class="{ 'is-invalid': errors.subtitle }"
                          ></textarea>
                          <span
                            class="form-text text-danger"
                            v-if="errors.subtitle"
                            >{{ errors.subtitle[0] }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div
                        class="imagePreviewWrapper"
                        :style="{ 'background-image': `url(${previewImage})` }"
                        @click="selectImage"
                      ></div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <label>Le contenu</label>
                      <ckeditor
                        :editor="editor"
                        v-model="form.content"
                        :config="editorConfig"
                      ></ckeditor>
                      <span
                        class="form-text text-danger"
                        v-if="errors.content"
                        >{{ errors.content[0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-lg-12 text-right">
                      <button
                        type="submit"
                        ref="kt_login_signin_submit"
                        class="btn btn-primary mr-2"
                      >
                        Enregistrer
                      </button>
                      <button type="reset" class="btn btn-secondary">
                        Annuler
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <!--end::Form-->
            </div>
            <!--end::Card-->
          </div>
        </div>
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "Form",
  components: {
    Multiselect
  },
  data() {
    return {
      initialize: "post/create",
      store: "post",
      method: "post",
      form: {},
      option: {
        categories: []
      },
      success: false,
      has_error: false,
      error: "",
      errors: {},
      previewImage: null,
      editor: ClassicEditor,
      editorConfig: {}
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "post/" + this.$route.params.id + "/edit";
      this.store = "post/" + this.$route.params.id;
      this.method = "post";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        vm.form = response.data.form;
        vm.option = response.data.option;
      });
    },
    setCatPostId() {
      this.form.cat_post_id = this.form.category.id;
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = e => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
      }
    },
    save() {
      let vm = this;
      let formData = new FormData();
      formData.append("image", this.$refs.fileInput.files[0]);
      let config = { headers: { "content-type": "multipart/form-data" } };
      Object.entries(vm.form).forEach(([key, value]) => {
        formData.append(key, value);
      });
      if (this.$route.meta.mode === "update") {
        formData.append("_method", "PUT");
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add(
        "spinner",
        "spinner-light",
        "spinner-right",
        "disabled"
      );
      ApiService[this.method](this.store, formData, config)
        .then(function(response) {
          if (response.data.success) {
            if (vm.$route.meta.mode === "create") {
              Swal.fire({
                position: "top-center",
                icon: "success",
                title: "L'article a été enrégistré avec succès.",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            } else {
              Swal.fire({
                position: "top-center",
                icon: "success",
                title: "L'article a été modifié avec succès.",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            }
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right",
            "disabled"
          );
        });
    }
  }
};
</script>

<style scoped></style>
